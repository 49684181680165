/** An enumeration of all feature flag keys currently defined in Launch Darkly. */
// (clewis): If you delete a feature flag in Launch Darkly, please remember to delete it here!
export enum FeatureFlagKey {
  // Please keep alphabetized:
  AI_MONITOR_2024_05 = "ai-monitor-2024-05",
  APP_GENERATION_2023_12 = "app-generation-2023-12",
  AUTOMATED_TASK_IMPROVEMENTS_2024_04 = "automated-task-improvements-2024-04",
  ASYNC_BULK_START_WORKFLOWS_2024_09 = "async-bulk-start-workflows-2024-09",
  BLUEPRINT_TRANSFER_20204_02 = "blueprint-transfer-2024-02",
  BLUEPRINT_TRANSFER_FIELD_MATCHING_2024_09 = "blueprint-transfer-field-matching-2024-09",
  BLUEPRINT_VARIATIONS_2024_08 = "blueprint-variations-08-24",
  BLUEPRINT_VERSIONS_2024_07 = "blueprint-versions-2024-07",
  DISABLE_OLD_APPROVALS_2023_09 = "disable-old-approvals-2023-09",
  FORM_GEN_2024_05 = "form-gen-2024-05",
  FRUIT_EMAIL_WEBFORM_REDESIGN_2024_04 = "fruit-email-redesign-2024-04",
  INACTIVE_ASSIGNEES_2024_08 = "inactive-assignees-2024-08",
  INTERNAL_DEV_ONLY_PAGES_2024_04 = "internal-dev-only-pages-2024-04",
  PERMISSIONS_V2_2024_01 = "permissions-v2-2024-01",
  PERMISSIONS_V3_2024_04 = "permissions-v3-2024-04",
  REMOVE_FORMS_PAGE_2024_08 = "remove-forms-page-2024-08",
  SAVE_FORM_AS_PDF_2024_04 = "save-form-as-pdf-2024-04",
  SCIM_PERMISSIONS_2024_04 = "scim-permissions-2024-04",
  SCIM_M2_2023_09_DESCOPED = "scim-m2-2023-10-descoped",
  SCIM_OAUTH_2024_07 = "scim-oauth-2024-07",
  SESSION_MANAGEMENT_2023_04 = "session-management-2023-04",
  SHAREPOINT_FILE_NAMING_CONVENTION_2024_04 = "sharepoint-file-naming-convention-2024-04",
  SPECTRUM_2023_08 = "spectrum-2023-08",
  SPECTRUM_BUTTON_TO_UPDATE_BLUEPRINT_FIELD_VERSIONS_2024_05 = "spectrum-button-to-update-blueprint-field-versions-2024-05",
  SPECTRUM_TASK_INFORMATION_ORDER_TOGGLE_2024_03 = "spectrum-task-information-order-toggle-2024-03",
  STAGE_0_2024_03 = "stage-0-2024-03",
  TASK_ALERTS_2024_08 = "task-alerts-2024-08",
  TEAM_ADMIN_PERMISSIONS_2024_06 = "team-admin-perms-2024-06",
  TOAST_REDESIGN_2024_07 = "toast-redesign-2024-07",
  UI_REDESIGN_WORKFLOW_PROGRESS_BAR_2024_08 = "ui-redesign-workflow-progress-bar-2024-08",
  UPDATED_WORKFLOW_SUMMARY_2023_06 = "updated-workflow-summary-2023-06",
  WORKFLOW_SUMMARY_2023_03 = "workflow-summary-2023-03",
  WORKSPACE_CREATION_2022_05 = "workspace-creation-2022-05",
  WORKLFOW_OWNER_M2_2024_04 = "workflow-owner-m2-2024-04",
}

/**
 * A map of feature flag keys to any keys that they depend on in order to be considered enabled.
 * This should be used sparingly as in practice feature flags should not depend on each other but
 * rather should be enabled per workspace segments. However, sometimes wide-reaching features will
 * be in development for long stretches of time and they need to start being incorporated into the
 * technical designs and functionality of new features.
 * NOTE: this should be kept in sync with the backend equivalent in 'flags.go'
 * (should we consider moving all flag evaluation to the backend to make this dry?)
 */
export const FeatureFlagKeyDependencyMap = new Map<FeatureFlagKey, FeatureFlagKey[]>([
  [
    FeatureFlagKey.BLUEPRINT_VERSIONS_2024_07,
    [FeatureFlagKey.PERMISSIONS_V2_2024_01, FeatureFlagKey.PERMISSIONS_V3_2024_04, FeatureFlagKey.SPECTRUM_2023_08],
  ],
  [
    FeatureFlagKey.BLUEPRINT_VARIATIONS_2024_08,
    [
      FeatureFlagKey.BLUEPRINT_VERSIONS_2024_07,
      FeatureFlagKey.PERMISSIONS_V2_2024_01,
      FeatureFlagKey.PERMISSIONS_V3_2024_04,
      FeatureFlagKey.SPECTRUM_2023_08,
    ],
  ],
]);
